<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-button
          block
          style="margin-top: 23px"
          variant="primary"
          @click="abrirModal"
        >
          Ingreso de Nema
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalNema"
      title="Formulario de Nema"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-title="Guardar Recepción"
      size="lg"
      hide-footer
      @hidden="limpiarModal"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay
            :show="show"
          >
            <b-row>
              <b-col
                cols="12"
                sm="8"
              >
                <h5 class="text-primary">
                  Tipo
                </h5>
                <b-form-group>
                  <v-select
                    v-model="nema.tipo"
                    class="textoQr qr-textarea"
                    label="nombre"
                    :options="tiposLampara"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Potencia
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Potencia"
                    rules="required"
                  >
                    <v-select
                      v-model="nema.potencia"
                      :state="errors.length > 0 ? false : null"
                      label="nombre"
                      value="nombre"
                      class="textoQr qr-textarea"
                      :options="potencias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <h5 class="text-primary">
                  Serie
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Serie"
                    rules="required"
                  >
                    <b-form-input
                      v-model="nema.serie"
                      class="textoQr qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="6"
              >
                <h5 class="text-primary">
                  Número de Factura
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Lote"
                    rules="required"
                  >
                    <b-form-input
                      v-model="nema.lote"
                      class="textoQr qr-textarea"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="origen === 'Manual'"
                cols="12"
              >
                <h5 class="text-primary">
                  Comentarios
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Comentarios"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="comentario"
                      class="qr-textarea"
                      no-resize
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="7">
                <h6 class="text-primary">
                  Fotografía de la Nema
                </h6>
              </b-col>
              <b-col
                cols="5"
                style="margin-top: -10px"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarFoto($event)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar Foto</span>
                </b-button>
              </b-col>
              <b-col
                style="justify-content: center; display: flex"
                cols="12"
              >
                <b-form-group>
                  <table>
                    <tr
                      v-for="(foto, index) in fotosIngresadas"
                      :key="index"
                    >
                      <td style="width: 280px">
                        <Imagen
                          :key="'recepcion-' + index"
                          :leyenda="'recepcion-' + index"
                          @cargar-file="imagenRecepcion(index, $event)"
                        />
                      </td>
                      <td>
                        <b-button
                          variant="danger"
                          @click="eliminarFoto()"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validationForm"
                >
                  Guardar Nema
                  <feather-icon
                    icon="SaveIcon"
                    size="15"
                    class="mr-60"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BForm, BOverlay, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { saveImagen } from '@/utils/imagenes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { findNemas, updateCreateNema } from '@/utils/laboratorio/nemas'

export default {
  components: {
    BFormGroup,
    BButton,
    BCol,
    BRow,
    BForm,
    Imagen,
    vSelect,
    BOverlay,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    potencias: {
      type: Array,
      required: true,
    },
    tiposLampara: {
      type: Array,
      required: true,
    },
    origen: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      show: false,
      modalNema: false,
      nema: {
        tipo: '',
        serie: null,
        potencia: '',
        estado: '',
        lote: '',
        tracking: [],
        imagenes: [],
        seguimiento: [],
        fechaCreacion: new Date(),
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      comentario: '',
      catalogoNema: [],
      fotosIngresadas: [''],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarIngresoNema()
            this.$bvModal.hide('modal-1')
          }
        })
    },
    async guardarIngresoNema() {
      try {
        this.show = true
        const filter = {
          where: {
            companyId: this.usuario.company.id,
            serie: this.nema.serie,
          },
        }
        const serieExistente = await findNemas(filter)
        if (serieExistente && serieExistente.length > 0) {
          mensajeError('Ya existe una nema con la misma serie en la base de datos.')
          return
        }
        const datosUsuario = usuario => ({
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
          email: `${usuario.email}`,
          telefono: '',
        })

        this.nema.usuarioCreacion = datosUsuario(this.usuario)
        const camposNema = {
          id: this.nema.id,
          tipo: this.nema.tipo,
          serie: this.nema.serie,
          potencia: this.nema.potencia,
          estado: this.nema.estado,
        }

        if (this.origen === 'Manual') {
          this.nema.estado = 'PRUEBAS'
          this.nema.tracking.push({
            usuario: datosUsuario(this.usuario),
            estado: 'INGRESADA',
            fecha: new Date(),
          })
          this.nema.seguimiento.push({
            usuario: datosUsuario(this.usuario),
            estado: 'INGRESADA',
            fecha: new Date(),
            comentarios: this.comentario,
            luminariaInicial: camposNema,
          })
        } if (this.origen === 'test') {
          this.nema.estado = 'INGRESADA'
          this.nema.tracking.push({
            usuario: datosUsuario(this.usuario),
            estado: 'INGRESADA',
            fecha: new Date(),
          })
        }

        const body = { ...this.nema }

        const item = await updateCreateNema(body, 2)

        if (!item) {
          mensajeError('Inconvenientes almacenando la recepción de la Nema!, intente nuevamente!')
          this.show = false
          return
        }

        const urlBucket = `nemas/${item.id}`
        const isMobile = window.screen.width <= 760

        // eslint-disable-next-line no-restricted-syntax
        for (const foto of this.fotosIngresadas) {
          if (foto !== '') {
          // eslint-disable-next-line no-await-in-loop
            const imagen = await saveImagen(urlBucket, this.localization, foto, 'INGRESADA', '', this.nema.usuarioCreacion, isMobile)
            if (imagen) {
              item.imagenes.push(imagen)
            }
          }
        }
        await updateCreateNema(item, 1)

        mensajeInformativo('Guardada', 'Luminaria Almacenada Correctamente!')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Luminaria Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Luminaria almacenada exitosamente!',
          },
        })
        this.$emit('cerrar-ingreso')
        this.modalNema = false
      } catch (error) {
        console.log(error)
        mensajeError('Se produjo un error al guardar la Nema.')
      } finally {
        this.show = false
      }
    },
    limpiarModal() {
      this.nema = {
        tipo: null,
        serie: null,
        potencia: '',
        estado: '',
        lote: '',
        tracking: [],
        imagenes: [],
        seguimiento: [],
        fechaCreacion: new Date(),
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      }
      this.comentario = null
    },
    abrirModal() {
      this.modalNema = true
    },
    agregarFoto() {
      this.fotosIngresadas.push('')
    },
    eliminarFoto(index) {
      if (this.fotosIngresadas.length > 1) {
        this.fotosIngresadas.splice(index, 1)
      }
    },
    imagenRecepcion(index, file) {
      this.$set(this.fotosIngresadas, index, file)
    },
  },
}
</script>
