<template>
  <div>
    <b-row>
      <b-col
        cols="8"
      >
        <h6 class="text-primary">
          Tipo
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="nema.tipo.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
      >
        <h6 class="text-primary">
          Potencia
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="nema.potencia.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Serie
        </h6>
        <b-form-group>
          <b-form-input
            id="disabledInput"
            :value="nema.serie"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
      >
        <h6 class="text-primary">
          Lote
        </h6>
        <b-form-group>
          <b-form-input
            id="Usuario"
            :value="nema.lote"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="nema.seguimiento.length !== 0"
        cols="12"
      >
        <cambio-estados-nemas
          :nema="nema"
          :pruebas-lampara="pruebasLampara"
          @cerrar-detalle="actualizarTabla"
        />
      </b-col>
      <b-col
        v-if="nema.usuarioCreacion.nombre === 'ADMINISTRADOR' && nema.estado === 'INGRESADA'"
        cols="12"
      >
        <confirmacion-datos-nemas
          :tipos-lampara="tiposLampara"
          :potencias="potencias"
          :nema="nema"
          @cerrar-ingreso="actualizarTabla"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <h6 class="text-primary">
          Seguimiento
        </h6>
        <vue-good-table
          :columns="columns"
          :rows="nema.seguimiento"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                @click="mostrarDetalle(props)"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'estado'">
              <span>{{ estadoSeguimiento(props.row.estado) }}</span>
            </span>
            <span v-else-if="props.column.field == 'fecha'">
              <span>{{ formatFecha(props.row.fecha) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
      <b-modal
        v-model="verDialogoDocumentoItem"
        hide-footer
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle del Seguimiento"
      >
        <b-row v-if="verDialogoDocumentoItem">
          <b-col
            v-if="seguimientoSeleccionado.usuarioAsignado"
            cols="5"
          >
            <h6 class="text-primary">
              Técnico Asignado
            </h6>
            <b-form-group>
              <b-form-input
                :value="seguimientoSeleccionado && seguimientoSeleccionado.usuarioAsignado && seguimientoSeleccionado.usuarioAsignado.nombre"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="mostrarSeguimiento(seguimientoSeleccionado.estado)"
            cols="12"
          >
            <h6 class="text-primary">
              Lista de Pruebas Realizadas
            </h6>
            <b-table
              :items="seguimientoSeleccionado.pruebas"
              :fields="camposTabla"
            >
              <template v-slot:cell(nombre)="{ item }">
                <h5 class="align-text-top text-uppercase">
                  {{ item.nombre }}
                </h5>
              </template>
              <template v-slot:cell(check)="{ item }">
                <b-form-checkbox
                  v-model="item.checkboxEstado"
                  disabled
                />
              </template>
            </b-table>
          </b-col>
          <b-col v-if="seguimientoSeleccionado.comentarios">
            <h6 class="text-primary">
              Comentarios
            </h6>
            <b-form-group>
              <b-form-textarea
                :value="seguimientoSeleccionado && seguimientoSeleccionado.comentarios"
                disabled
                no-resize
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="mostrarColDetalle"
            cols="12"
            sm="12"
          >
            <h6 class="text-primary">
              Fotografías
            </h6>
            <vue-good-table
              :columns="columnsDetalle"
              :rows="imagenesSeguimiento"
              style-class="vgt-table condensed"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'detalleFoto'">
                  <ImagenShowReemplazo
                    :posicion-imagen="getIndexImage(props)"
                    :ticket="nema"
                    :clave="'imagenes'"
                    :origen="origenes.nemas"
                  />
                </span>
                <span v-else-if="props.column.field === 'fechaHora'">
                  <span>{{ formatFecha(props.row.fechaHora) }}</span>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BTable, BButton, BFormInput, BFormGroup, BFormCheckbox, BFormTextarea, VBModal,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { calcularFecha } from '@/utils/fechas'
import Ripple from 'vue-ripple-directive'
import { origenes } from '@/utils/origenesImagen'
import ImagenShowReemplazo from '@/components/componenteImagenActualizar/ImagenShowReemplazo.vue'
import CambioEstadosNemas from '@/components/laboratorio/nemas/cambioEstadosNemas.vue'
import ConfirmacionDatosNemas from '@/components/laboratorio/nemas/confirmacionDatosNemas.vue'
import { getUtilsLaboratorio } from '@/utils/laboratorio/luminarias'

export default {
  components: {
    ConfirmacionDatosNemas,
    CambioEstadosNemas,
    ImagenShowReemplazo,
    BCol,
    BRow,
    BTable,
    BButton,
    BFormInput,
    BFormGroup,
    VueGoodTable,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    nema: {
      type: Object,
      required: true,
    },
    potencias: {
      type: Array,
      required: true,
    },
    tiposLampara: {
      type: Array,
      required: true,
    },
    pruebasLampara: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      origenes,
      mostrarColDetalle: true,
      verDialogoDocumentoItem: false,
      seguimientoSeleccionado: null,
      imagenesSeguimiento: [],
      columns: [
        {
          label: 'Estado',
          field: 'estado',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fecha',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      camposTabla: [
        { key: 'nombre', label: 'Nombre' },
        { key: 'check', label: '' },
      ],
      columnsDetalle: [
        {
          label: 'Fecha / Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalleFoto',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  created() {
    this.estados = getUtilsLaboratorio(3)
  },
  methods: {
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    mostrarSeguimiento(estado) {
      return estado === this.estados.PRUEBAS.value || estado === this.estados.GARANTIA.value
    },
    getIndexImage(item) {
      const imagen = item.row
      return this.nema.imagenes.findIndex(obj => obj.nombre === imagen.nombre && obj.tipo === imagen.tipo && obj.urlInternal === imagen.urlInternal)
    },
    mostrarDetalle(params) {
      this.seguimientoSeleccionado = params.row
      this.imagenesSeguimiento = this.nema.imagenes.filter(item => item.tipo === params.row.estado)
      this.verDialogoDocumentoItem = true

      this.mostrarColDetalle = this.imagenesSeguimiento.length !== 0
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    actualizarTabla() {
      this.$emit('actualizar')
    },
  },
}
</script>
