var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticStyle:{"margin-top":"23px"},attrs:{"block":"","variant":"primary"},on:{"click":_vm.abrirModal}},[_vm._v(" Ingreso de Nema ")])],1)],1),_c('b-modal',{attrs:{"title":"Formulario de Nema","no-close-on-backdrop":true,"no-close-on-esc":true,"ok-title":"Guardar Recepción","size":"lg","hide-footer":""},on:{"hidden":_vm.limpiarModal},model:{value:(_vm.modalNema),callback:function ($$v) {_vm.modalNema=$$v},expression:"modalNema"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-overlay',{attrs:{"show":_vm.show}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Tipo ")]),_c('b-form-group',[_c('v-select',{staticClass:"textoQr qr-textarea",attrs:{"label":"nombre","options":_vm.tiposLampara},model:{value:(_vm.nema.tipo),callback:function ($$v) {_vm.$set(_vm.nema, "tipo", $$v)},expression:"nema.tipo"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Potencia ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Potencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"textoQr qr-textarea",attrs:{"state":errors.length > 0 ? false : null,"label":"nombre","value":"nombre","options":_vm.potencias},model:{value:(_vm.nema.potencia),callback:function ($$v) {_vm.$set(_vm.nema, "potencia", $$v)},expression:"nema.potencia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Serie ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Serie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"textoQr qr-textarea",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.nema.serie),callback:function ($$v) {_vm.$set(_vm.nema, "serie", $$v)},expression:"nema.serie"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Número de Factura ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Lote","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"textoQr qr-textarea",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.nema.lote),callback:function ($$v) {_vm.$set(_vm.nema, "lote", $$v)},expression:"nema.lote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.origen === 'Manual')?_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Comentarios ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Comentarios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"qr-textarea",attrs:{"no-resize":"","state":errors.length > 0 ? false:null},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2166050505)})],1)],1):_vm._e()],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" Fotografía de la Nema ")])]),_c('b-col',{staticStyle:{"margin-top":"-10px"},attrs:{"cols":"5"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.agregarFoto($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Agregar Foto")])],1)],1),_c('b-col',{staticStyle:{"justify-content":"center","display":"flex"},attrs:{"cols":"12"}},[_c('b-form-group',[_c('table',_vm._l((_vm.fotosIngresadas),function(foto,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"280px"}},[_c('Imagen',{key:'recepcion-' + index,attrs:{"leyenda":'recepcion-' + index},on:{"cargar-file":function($event){return _vm.imagenRecepcion(index, $event)}}})],1),_c('td',[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.eliminarFoto()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Eliminar")])],1)],1)])}),0)])],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"},attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Guardar Nema "),_c('feather-icon',{staticClass:"mr-60",attrs:{"icon":"SaveIcon","size":"15"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }